












import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup(_) {
    const containerBlackList = new Set(
      ['BlogHero', 'Newsletter', 'SliderMainBlok', 'container', 'CategoriesTilesSection', 'AboutUsSection', 'IFrameBlok'],
    );
    const isContainer = (component: string) => !containerBlackList.has(component);

    return { isContainer };
  },
});
